import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import GWlp from "src/components/modules/gwlp_de"
const GWlpData = import("./data/data.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
    <title>Get Templates Now</title>
    <style type="text/css">{`

       div#ctadisclosurenewtab-module--ctadisclaimer {
        margin:0 auto !important;
        font-size:13px;
        width:600px;
       }

      #ctadisclosurenewtab-module--ctadisclaimer p {
        font-size:12px;
        line-height:145%;
        font-weight: 300;
       }

    `}
    </style>
    </Helmet>
    <section>
      <GWlp data={GWlpData}>
      </GWlp>

      </section>
    </HomepageLayout>


   )
  }
